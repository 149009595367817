import React from "react"
import Ornament from "../common/Ornament"

function Nyar() {
  return (
    <div className="w-full  mt-8 md:mt-0">
      <fieldset className="border-cherry border-2 px-4 rounded-md text-center mx-auto menu-box">
        <legend>
          <h2 className="text-2xl uppercase acme text-cherry relative inline-block -mt-6 px-8 bg-transparent">
            Nyår
          </h2>
        </legend>

        <div className="my-6 text-sm">
          <div className="mb-4">
            <h3 className="text-lg my-2 uppercase acme">Förrätt</h3>

            <p className="text-lg italic">
              Hummerbisque med hummerkött och parmesanflarn & fänkål
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg my-2 uppercase acme">Huvudrätt</h3>
            <p className="text-lg italic">
              Oxfilé med tryffel&gruyere potatisgratäng, potvinssky, confiterad
              purjolök och salt rostade rotfrukter
            </p>
          </div>
          <div className="mb-4">
            <h3 className="text-lg my-2 uppercase acme">Efterrätt</h3>
            <p className="text-lg italic">
              Mandel pannacotta med rårörda bär och vit choklad crumble
            </p>
          </div>
          <div className="w-6 mx-auto my-8">
            <div className="max-w-full ">
              <Ornament />
            </div>
          </div>
          <p className="text-lg mt-4 italic font-bold">
            {" "}
            Tre rätter, 595kr per person
          </p>
          <div className="mb-4">
            <h3 className="text-lg my-2 uppercase acme">Extra</h3>
            <p className="text-lg italic">
              Det kommer att finnas charkuterier, snacks, färsk tryffel,
              anklever att köpa på plats.
            </p>
          </div>
          <div className="w-6 mx-auto my-8">
            <div className="max-w-full ">
              <Ornament />
            </div>
          </div>
          <p className="my-4">
            Beställ via{" "}
            <a
              href="mailto:info@clemenskott.se"
              title="Boka"
              className="underline"
            >
              info@clemenskott.se
            </a>{" "}
            / 040-22 22 96
          </p>
          <p>Sista dag för beställning: 28/12</p>
        </div>
      </fieldset>
      <div className="w-full px-16 py-8 h-full"></div>
    </div>
  )
}

export default Nyar
