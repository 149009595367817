import React from "react"
import Ornament from "../common/Ornament"

import { StaticImage } from "gatsby-plugin-image"

export default function Jul() {
  return (
    <div className="w-full ">
      <fieldset className="border-cherry border-2 px-2 rounded-md text-center mx-auto menu-box">
        <legend>
          <h2 className="text-2xl uppercase acme text-cherry relative inline-block -mt-6 px-8 bg-transparent">
            Jul
          </h2>
        </legend>

        <div className="my-6 text-sm">
          <div className="mb-4">
            <h3 className="text-lg my-2 uppercase acme">Julkasse</h3>
            <p className="mb-2">(För fyra personer)</p>
            <p className="text-lg italic">
              Köttbullar (rullade och ångade, 200g)
              <br />
              Prinskorv (200g)
              <br />
              Revben med glace
              <br />
              Rödkål med gåsfett
              <br />
              Leverpastej (100g, Heberlein)
              <br />
              Rökt korv (Heberlein)
              <br />
              Fransk lantpaté (100g)
              <br />
              Julsenap
              <br />
            </p>
          </div>
          <p className="text-lg mt-4 italic font-bold"> 849 kr</p>
          <div className="w-6 mx-auto my-8">
            <div className="max-w-full ">
              <Ornament />
            </div>
          </div>

          <p className="text-lg mt-4 italic font-bold"> Utöka julkassen:</p>
          <p className="text-lg italic">
            Julskinka (hel bit, färdiggriljerad) <br />
            Brunkål
            <br />
            Pressylta
          </p>
          <p className="text-lg mt-4 italic font-bold"> 395kr, 4 personer</p>
          <br />
          <div className="mb-4">
            <h3 className="text-lg my-2 uppercase acme">Julskinka</h3>
            <div className="mb-4">
              Rosesbergs gård utanför Ekeby / Helsingborg
            </div>
            <p className="text-lg italic">
              <span className="not-italic">Lättrökt:</span>
              <br />
              Rå, 350kr/kg
              <br />
              Kokt, 350kr/kg
              <br />
              Griljerad, 380kr/kg
              <br />
              <span className="not-italic">Sockersaltad:</span>
              <br />
              Rå, 350kr/kg
              <br />
              Kokt, 350kr/kg
              <br />
              Griljerad, 380kr/kg
              <br />
            </p>
          </div>

          <div className="w-6 mx-auto my-8">
            <div className="max-w-full ">
              <Ornament />
            </div>
          </div>

          {/* <h3 className="text-lg my-4 uppercase acme">Jullådor</h3>
          <p className="text-lg italic">
            Vår egen äpple/saffransglögg <br />
            Vår egen clementinmarmelad
            <br />
            Brillat-Savarin Ost
            <br />
            Nocellaraoliver
            <br />
            Stag rosmarinkex
            <br />
            <br />
            Inslagen i cellofan och rött band
          </p>
          <p className="text-lg mt-4 italic font-bold"> 320 kr</p>
          <div className="w-6 mx-auto text-center py-6">
            <Ornament />
          </div>
          <p className="text-lg italic">
            Vår egen äpple/saffransglögg <br />
            Vår egen clementinmarmelad
            <br />
            Brillat-Savarin Ost
            <br />
            Nocellaraoliver
            <br />
            Stag rosmarinkex <br />
            Tryffelsalami
            <br />
            Olivolja 250 ml Calvi Classico
            <br />
            <br />
            Inslagen i cellofan och rött band
          </p>
          <p className="text-lg mt-4 italic font-bold"> 495 kr</p>
          <div className="my-4 w-full px-16">
            <StaticImage src="../../images/jullada.jpeg" />
          </div>
          <div className="w-6 mx-auto my-8">
            <div className="max-w-full ">
              <Ornament />
            </div>
          </div> */}

          <p className="my-4">
            Beställ via{" "}
            <a
              href="mailto:info@clemenskott.se"
              title="Boka"
              className="underline"
            >
              info@clemenskott.se
            </a>{" "}
            / 040-22 22 96
            <br />
          </p>
        </div>
      </fieldset>
    </div>
  )
}
